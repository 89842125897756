<!-- 答题组件 -->
<template>
  <div class="topic-item-panel">
    <!-- 文章带题目的 -->
    <template
      v-if="
        topicItemInfo.questionType &&
        (topicItemInfo.questionType === 3 || topicItemInfo.questionType == 4)
      "
    >
      <p v-if="topicItemInfo.questionType === 3">
        <el-tag> {{ topicItemInfo.articleName }}</el-tag>
      </p>
      <p v-if="topicItemInfo.questionType === 4"><el-tag>听力</el-tag></p>
      <p class="articleTitle">{{ topicItemInfo.articleTitle }}</p>

      <p class="articleTitle">{{ topicItemInfo.articleDes }}</p>
      <div class="readingCom" v-html="topicItemInfo.articleCon"></div>
      <div
        v-for="(q, key) in topicItemInfo.questionList"
        :key="key"
        style="margin-top: 20px"
      >
        <p
          class="question-tit"
          v-if="q.questionType && q.questionType === 2"
          :id="step(q.value)"
        >
          <span class="tip-class">
            {{
              q.topicType === 1 ? "单选" : q.topicType === 2 ? "多选" : "判断"
            }}</span
          >
          <span style="font-weight: bold; flex-shrink: 0"> {{ q.num }}</span
          >.
          <audio
            controls
            controlsList="nodownload"
            :src="`/video-dev${q.file}`"
            v-if="q.file"
          ></audio>
          <span v-html="q.question"></span>
        </p>
        <p
          class="question-tit"
          v-else-if="q.questionType && q.questionType === 3"
          :id="step(q.value)"
        >
          <span class="tip-class"> 填空题</span>
          <span style="font-weight: bold; flex-shrink: 0"> {{ q.num }}</span
          >.
          <el-input v-model="q.answer" placeholder="请输入内容"></el-input>
        </p>
        <p class="question-tits" v-else :id="step(q.num)">
          <span class="tip-class">{{
            q.topicType === 1 ? "单选" : q.topicType === 2 ? "多选" : "判断"
          }}</span>
          <span style="font-weight: bold; flex-shrink: 0">{{ q.num }}</span
          >.
          <span v-html="q.question"></span>
        </p>
        <ul class="options-box" v-if="q.questionType !== 3">
          <li
            :class="['option-item', computedClass(item, q)]"
            v-for="(item, index) in q.options"
            :key="index"
            @click="handleChange(item, q)"
          >
            <!-- 单选按钮 选中与非选中状态 -->
            <template v-if="q.topicType === 1 || q.topicType === 3">
              <img
                v-if="q.answer === item.value"
                class="icon-img"
                src="../../../assets/img/icon_radioed@2x.png"
                alt="icon"
              />
              <img
                v-else
                class="icon-img"
                src="../../../assets/img/icon_radio@2x.png"
                alt="icon"
              />
            </template>
            <!-- 复选按钮 选中与非选中状态 -->
            <template v-else-if="q.topicType === 2">
              <img
                v-if="q.answer.includes(item.value)"
                class="icon-img"
                src="../../../assets/img/icon_checked@2x.png"
                alt="icon"
              />
              <img
                v-else
                class="icon-img"
                src="../../../assets/img/icon_checkbox@2x.png"
                alt="icon"
              />
            </template>
            <p class="item-tit">
              {{ item.num }}、 <span v-html="item.label"></span>
            </p>
          </li>
        </ul>
      </div>
    </template>
    <!-- 纯题目 -->
    <template
      v-if="
        topicItemInfo.questionType &&
        (topicItemInfo.questionType === 1 || topicItemInfo.questionType == 2)
      "
    >
      <audio
        v-if="topicItemInfo.file"
        controls
        controlsList="nodownload"
        :src="`/video-dev${topicItemInfo.file}`"
      ></audio>
      <p
        class="question-tit"
        v-if="topicItemInfo.questionType && topicItemInfo.questionType === 2"
        :id="step(topicItemInfo.value)"
        style="font-weight: bold; flex-shrink: 0"
      >
        <span class="tip-class">{{
          topicItemInfo.topicType === 1
            ? "单选"
            : topicItemInfo.topicType === 2
            ? "多选"
            : "判断"
        }}</span>
        <span>{{ topicItemInfo.num }}、</span>
        <span v-html="topicItemInfo.question"></span>
      </p>
      <p class="question-tit" v-else :id="step(topicItemInfo.num)">
        <span class="tip-class">{{
          topicItemInfo.topicType === 1
            ? "单选"
            : topicItemInfo.topicType === 2
            ? "多选"
            : "判断"
        }}</span>
        <span style="font-weight: bold; flex-shrink: 0">
          {{ topicItemInfo.num }}</span
        >.
        {{ topicItemInfo.question }}
      </p>
      <ul class="options-box">
        <li
          :class="['option-item', computedClass(item, topicItemInfo)]"
          v-for="(item, index) in topicItemInfo.options"
          :key="index"
          @click="handleChange(item, topicItemInfo)"
        >
          <!-- 单选按钮 选中与非选中状态 -->
          <template
            v-if="
              topicItemInfo.topicType === 1 || topicItemInfo.topicType === 3
            "
          >
            <img
              v-if="item.value === topicItemInfo.answer"
              class="icon-img"
              src="../../../assets/img/icon_radioed@2x.png"
              alt="icon"
            />
            <img
              v-else
              class="icon-img"
              src="../../../assets/img/icon_radio@2x.png"
              alt="icon"
            />
          </template>
          <!-- 复选按钮 选中与非选中状态 -->
          <template v-else-if="topicItemInfo.topicType === 2">
            <img
              v-if="topicItemInfo.answer.includes(item.value)"
              class="icon-img"
              src="../../../assets/img/icon_checked@2x.png"
              alt="icon"
            />
            <img
              v-else
              class="icon-img"
              src="../../../assets/img/icon_checkbox@2x.png"
              alt="icon"
            />
          </template>
          <p class="item-tit">
            {{ item.num }}、 <span v-html="item.label"></span>
          </p>
        </li>
      </ul>
    </template>
    <!-- 文章带题目的 -->
    <template
      v-if="topicItemInfo.questionType && topicItemInfo.questionType === 5"
    >
      <p>
        <el-tag>{{ topicItemInfo.articleName }}</el-tag>
      </p>
      <div class="components">
        <span style="font-weight: bold; flex-shrink: 0; padding-top: 10px">
          {{ topicItemInfo.num }}.
        </span>
        <div class="readingCom" v-html="topicItemInfo.articleCon"></div>
      </div>

      <div class="answer" style="height: 115px">
        <el-input
          v-model="value1"
          type="textarea"
          placeholder="请输入内容"
          style="height: 115px"
          @input="handleChange(1, topicItemInfo)"
        ></el-input>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      default: "",
    },
    topicItemInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      active: [],
      value1: null,

      // active: (this.topicItemInfo.topicType === 1 || this.topicItemInfo.topicType === 3) ? null : []
    };
  },
  watch: {
    value: {
      handler(val) {
        this.active = val || [];
      },
      immediate: true,
    },
  },
  model: {
    prop: "value",
    event: "handleChange",
  },
  methods: {
    computedClass(item, q) {
      let classTip = "";
      if ([1, 3].includes(q.topicType) && item.value === q.answer) {
        // 单选、判断
        classTip = "option-item-active";
      } else if (q.topicType === 2 && q.answer.includes(item.value)) {
        // 多选
        classTip = "option-item-active";
      }
      return classTip;
    },
    step(i) {
      return "step" + i;
    },
    handleChange(item, q) {
      if (q.questionType === 5) {
        q.answer = this.value1;
      } else {
        this.active = q.answer;
        if (q.topicType === 1 || q.topicType === 3) {
          // 单选题 和 判断题
          if (this.active === item.value) {
            // 选中则点击取消选中
            this.active = null;
          } else {
            // 没有选中点击选中
            this.active = item.value;
          }
        } else if (q.topicType === 2) {
          // 多选题
          if (this.active.includes(item.value)) {
            // 多选题答案中存在，再次点击取消选中状态
            const cIndex = this.active.indexOf(item.value); // 找到当前项索引
            this.active.splice(cIndex, 1); // 移除当前项
          } else if (q.questionType == 5) {
            q.answer = this.value1;
          } else {
            // 不存在则添加
            this.active.push(item.value);
          }
          this.active.sort(); // 正序排序
        }
        q.answer = this.active;
        this.$emit("handleChange", this.active);
        this.$emit("clearNoAnswer");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.topic-item-panel {
  padding: 7px 20px 30px;
  width: 100%;

  & > p {
    margin-bottom: 10px;
  }

  > :nth-child(2) {
    margin-top: 20px;
  }

  .question-tit {
    color: #333;
    font-size: 14px;
    margin-bottom: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    display: flex;
    align-items: center;

    audio {
      margin: 0 15px;
    }
      ::v-deep{
         .el-input{
          width: 300px;
          margin-left: 10px;
         }
      }
    .tip-class {
      display: inline-block;
      width: 34px;
      height: 20px;
      text-align: center;
      border-radius: 3px;
      font-size: 13px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      line-height: 19px;
      color: #ffffff;
      word-break: keep-all;
      background: #037ce4;
      margin-right: 10px;
    }
  }
  .question-tits {
    color: #333;
    font-size: 14px;
    margin-bottom: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    display: flex;
    flex-wrap: wrap;

    audio {
      margin: 0 15px;
    }

    .tip-class {
      margin-right: 10px;
      display: inline-block;
      width: 34px;
      height: 20px;
      text-align: center;
      border-radius: 3px;
      font-size: 13px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      line-height: 19px;
      color: #ffffff;
      word-break: keep-all;
      background: #037ce4;
      // margin-left: 10px;
    }
  }

  .options-box {
    width: 100%;

    .option-item {
      width: 100%;
      background: #f7f8f9;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 10px;
      display: flex;
      border: 1px solid transparent;
      box-sizing: border-box;

      &:last-child {
        margin-bottom: 0;
      }

      .icon-img {
        width: 16px;
        height: 16px;
        margin-top: 3px;
        margin-right: 10px;
      }

      .item-tit {
        font-size: 13px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        white-space: pre-wrap;
      }

      .check-tip {
        padding: 5px;
        color: #db192a;
      }
    }

    // 单选题和多选题
    .option-item-active {
      background: #e9f5ff;

      .item-tit {
        color: #037ce4;
      }
    }

    // 答题正确
    .right-style {
      background: #eff4ff;

      .item-tit {
        color: #4575e8;
      }
    }

    // 答题错误
    .wrong-style {
      background: #fff0f0;

      .item-tit {
        color: #e73a39;
      }
    }
  }

  .readingCom {
    font-size: 14px;
    font-weight: 400;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
    text-indent: 10px;

    ::v-deep {
      p {
        margin-top: 10px !important;
      }
    }
  }
}

.answer {
  width: 100%;
  margin-top: 30px;

  ::v-deep {
    .el-textarea__inner {
      height: 115px !important;
      min-height: 115px !important;
    }
  }
}

.components {
  display: flex;
}

.articleTitle {
  text-indent: 10px;
}



</style>
